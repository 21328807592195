module controllers {

    export module applicationcore {


        interface ITerminalCodeCtrlScope extends ng.IScope {
        }

        interface ITerminalCodeParams extends ng.ui.IStateParamsService {

        }

        export class terminalCodeListCtrl {

            static $inject = ["$scope",
            "$rootScope",
            "generalService",
            "$q",
            "bsLoadingOverlayService",
            "entityService",
            "$timeout",
            "$uibModal",
            "$state",
            "$stateParams",
            "$transitions",
            "TerminalCodeService"
        ];

        IsLoading: boolean = false;
        gridApi: uiGrid.IGridApi;
        allDirtyRows: Array<interfaces.applicationcore.TerminalCodeDisplay>;
        searchAccordian: boolean = false;
        myHook: any;
        showAllTerminals: boolean = false;

        terminalCodeSearch: interfaces.applicationcore.ITerminalCodeSearch = {
        };

        paginationOptions = {
            pageNumber: 1,
            pageSize: 25
        };

        ctrlName: string = "Terminal Code List";
        sortName: string;
        sortDirection: string;

        filterNames: string[] = ["CountryCode","LocationName","Code","TerminalName","TerminalCode","Active"];

        filterList: interfaces.applicationcore.IKeyValue[] = [];            

        constructor(private $scope: ITerminalCodeCtrlScope,
            private $rootScope: interfaces.applicationcore.IRootScope,
            public generalService: interfaces.applicationcore.IGeneralService,
            private $q: ng.IQService, private bsLoadingOverlayService,
            private entityService: interfaces.applicationcore.IEntityService,
            private $timeout: ng.ITimeoutService,
            private $uibModal: ng.ui.bootstrap.IModalService,
            private $state: ng.ui.IStateService,
            private $stateParams: ITerminalCodeParams,
            private $transitions: ng.ui.core.ITransition,
            private terminalCodeService: interfaces.applicationcore.ITerminalCodeService,
        ) {

            this.loadTerminalCodes();

                      
            this.myHook = $transitions.onSuccess({
                to: 'auth.MasterData.Terminal',
                from: 'auth.MasterData.Terminal.**'
            }, () => {
                this.loadTerminalCodes();

            });

            $scope.$on('$destroy', () => {
                $transitions.onStart({}, this.myHook)
            });
        }


        loadTerminalCodes(){
            this.bsLoadingOverlayService.wrap({
                referenceId: 'terminalCodeList'
            },
                () => {
                    var searchObject: interfaces.applicationcore.ISearchObject = {
                        filters: [],
                        sorts: []
                    }

                    angular.forEach(this.terminalCodeSearch, (n, key) => {
                        if (n["Id"]) {
                            searchObject.filters.push({ Name: key, Value: n.Id });
                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    });

                    searchObject.filters.push({ Name: "IsVesselSchedulesActive", Value: this.showAllTerminals});

                    if (this.gridApi) {
                        var grid = this.gridApi.grid;
                        angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                            if (n.filters[0].term) {
                                searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                            }

                            if (n.sort.direction) {
                                searchObject.sorts.push({
                                    Name: n.name,
                                    SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                    SortOrder: n.sort.priority
                                });
                            }
                        });
                    }

                    var params = {
                        connectSearch: searchObject,
                        numberRecords: this.paginationOptions.pageSize,
                        pageNumber: this.paginationOptions.pageNumber,
                    }

                    return this.terminalCodeService.getList().query(params, (result: Array<interfaces.applicationcore.TerminalCodeDisplay>) => {                       
                        this.gvwTerminals.data = result;
                        if (result[0]) {
                            this.gvwTerminals.totalItems = result[0].NumRecs;
                        } else {
                            this.gvwTerminals.totalItems = 0;
                        }                            
                        this.$timeout(() => {
                            if (this.gridApi) {
                                this.getFilters();
                                this.setFilters();
                            }
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }


            public gvwTerminals: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },               
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.terminalCodeListCtrl.editTerminal(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, 
                {
                    name: "CountryCode",
                    displayName: "Country",
                    field: "CountryCode",
                    width: 120,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, 
                {
                    name: "LocationName",
                    displayName: "Location",
                    field: "LocationName",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                }, 
                {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },       
                {
                    name: "TerminalName",
                    displayName: "Terminal Name",
                    field: "TerminalName",
                    width: 550,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                },       
                {
                    name: "TerminalCode",
                    displayName: "Terminal Code",
                    field: "TerminalCode",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,                
                }
                , {
                    name: "Active",
                    displayName: "Active",
                    enableFiltering: false,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,                        
                    cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.Active">`,
                    width: 100
                }
                ]                
            }


            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.terminalListFilterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.terminalListSortChange(gridApi);
                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });


                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.gridApi.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.gridApi.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            changeShowAll(){
                this.DoSearch();
            }
            
            terminalListSortChange(gridApi) {
                this.DoSearch();
            }
            
            terminalListFilterChange(gridApi: uiGrid.IGridApi) {
                this.DoSearch();
            }

            ListPageChange(newPage, pageSize) {

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            DoSearch() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'vesselScheduleList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.terminalCodeService, (n, key) => {
                            if (n) {
                                if (n["Id"]) {
                                    searchObject.filters.push({ Name: key, Value: n.Id });
                                } else {
                                    if (n instanceof moment) {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    } else {
                                        searchObject.filters.push({ Name: key, Value: n });
                                    }
                                }
                            }
                        });

                        searchObject.filters.push({ Name: "IsVesselSchedulesActive", Value: this.showAllTerminals});

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.terminalCodeService.getList().query(params, (result: Array<interfaces.logistics.VesselScheduleDisplay>) => {
                            var currentDirtyRows: Array<interfaces.logistics.VesselScheduleDisplay> = [];



                            this.gvwTerminals.data = result;

                            if (currentDirtyRows.length > 0) {
                                this.$timeout(() => {
                                    _.forEach(currentDirtyRows, (value) => {
                                        this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                                    });
                                });
                            }

                            if (result[0]) {
                                this.gvwTerminals.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwTerminals.totalItems = 0;
                            }

                                this.$timeout(() => {
                                if (this.gridApi) {
                                    //this.getFilters();
                                    //this.setFilters();
                                }
                            });
                            
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            search_click() {
                this.searchAccordian = false;
                this.DoSearch();
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.terminalCodeSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.gridApi) {

                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.terminalCodeService.GetTerminalsExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwTerminals.data = [];
                    this.gvwTerminals.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }  

            getFilters() {
                _.forEach(this.filterNames, (name: string) => {

                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            editTerminal(terminalId: number){
                this.$state.go("auth.MasterData.Terminal.Update", { terminalId: terminalId });
            }



        }


        angular.module("app").controller("terminalCodeListCtrl", controllers.applicationcore.terminalCodeListCtrl);    
    }
}